import {Button, Text} from '@mantine/core';
import {openConfirmModal} from '@mantine/modals';

export interface DeleteButtonProps {
  handleDelete: (id: number) => void
  itemId: number
}

export const DeleteButton = ({handleDelete, itemId}: DeleteButtonProps) => {
  const openDeleteModal = () =>
    openConfirmModal({
      title: 'Excluir',
      centered: true,
      children: (
        <Text size="sm">
          Tem certeza que deseja excluir esse item?
        </Text>
      ),
      labels: {confirm: 'Remover', cancel: "Cancelar"},
      confirmProps: {color: 'red'},
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDelete(itemId),
    });

  return (<Button onClick={openDeleteModal} color="red">Excluir</Button>)
}
