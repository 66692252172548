import {NavLink} from "@mantine/core";
import {IconMailbox, IconMailForward} from "@tabler/icons-react";
import {Link} from "react-router-dom";

const MailLinks = () => {
  return (
    <>
      <Link to={"/aliases"}><NavLink label="Alias" icon={<IconMailForward size={16}/>}/></Link>
      <Link to={"/emails"}><NavLink label="Emails" icon={<IconMailbox size={16}/>}/></Link>
    </>
  )
}

export default MailLinks;
