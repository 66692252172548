import {useAuthenticator} from "@aws-amplify/ui-react";
import {useState} from "react";
import {useForm} from "@mantine/form";
import {Button, Group, Notification, TextInput} from "@mantine/core";
import {IconCheck} from "@tabler/icons-react";
import {SubmitFormSite} from "../../Services/Forms";
import {useTranslation} from "react-i18next";

const AddSite = () => {
  const {user} = useAuthenticator((context) => [context.user]);
  const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const [hasSuccess, setHasSuccess] = useState(false);
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      name: '',
    },
  });

  return (<div style={{maxWidth: 320, margin: 'auto'}}>
      {hasSuccess && <Notification icon={<IconCheck size={18}/>} color="teal" title={t('form.site.create.title')}
                                   onClose={() => setHasSuccess(false)}>
        {t('form.site.create.success')}
      </Notification>}
      <TextInput mt="xs" label="Name" placeholder="Name" {...form.getInputProps('name')} />

      <Group position="center" mt="xl">
        <Button
          variant="outline"
          onClick={() => SubmitFormSite(form.values, token)
            .then(() => {
              setHasSuccess(true)
              form.reset()
            })}
        >
          {t('form.submit')}
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            form.reset()
          }}
        >
          {t('form.clear')}
        </Button>
      </Group>
    </div>)
}

export default AddSite;
