import {Heading} from "@aws-amplify/ui-react";
import {Divider, Grid, Paper} from "@mantine/core";
import React from "react";


export const Home = () => {
  return (<>
    <Paper shadow="xl" radius="xl" p="xl">
      <Heading level={1}>Bem vindo</Heading>
      <Divider />
      <Grid>
        <Grid.Col span={4}>1</Grid.Col>
        <Grid.Col span={4}>2</Grid.Col>
        <Grid.Col span={4}>3</Grid.Col>
      </Grid>
    </Paper>
  </>);
}
