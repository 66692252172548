import {ApiForm} from "../../SDK/formsapi";
import {useTranslation} from "react-i18next";


const Form = ({form}: {form: ApiForm}) => {
  const {t} = useTranslation();

  return (
    <div>
      <p>{t('Form.')}: {form.name}</p>
      <p>Email: {form.email}</p>
      <p>Subject: {form.subject}</p>
      <p>Message: {form.message}</p>
    </div>
  )
}

export default Form
