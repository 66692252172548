import {useEffect, useState} from "react";
import {ApiMailUserRes} from "../../SDK/mailmgn";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {DeleteEmail, GetEmails} from "../../Services/MailMgnService";
import {IconSquarePlus} from "@tabler/icons-react";
import {Accordion, Box, Button, Drawer, Flex, Paper, Text} from "@mantine/core";
import EmailForm from "./EmailForm";
import {DeleteButton} from "./DeleteButton";

const Email = () => {
  const [opened, setOpened] = useState(false);
  const [emails, setEmails] = useState<ApiMailUserRes[]>([]);
  const {user} = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    isLoading && GetEmails(user.getSignInUserSession()?.getIdToken().getJwtToken())
      .then((d) => setEmails(d ? d : []));
    setIsLoading(false)
  }, [user, isLoading])

  const handleDelete = (email_id: number | undefined) => {
    email_id && DeleteEmail(email_id, user.getSignInUserSession()?.getIdToken().getJwtToken())
      .then(() => setIsLoading(true))
  }

  const rows = emails.map((e, i) => (
    <Accordion.Item value={`item-${i}`} key={i}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Accordion.Control>
          <Flex
            gap="sm"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text>{e.email}@{e.domain}</Text>
          </Flex>
        </Accordion.Control>
      </Box>
      <Accordion.Panel>
        <DeleteButton itemId={e.id} handleDelete={handleDelete}/>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
          setIsLoading(true)
        }}
        title="Create Alias"
        padding="xl"
        size="xl"
      >
        <EmailForm/>
      </Drawer>
      <Paper shadow="xl" radius="xl" p="xl">
        <Button leftIcon={<IconSquarePlus/>} variant="outline" color="blue"
                onClick={() => setOpened(true)}>New</Button>
        <Accordion chevronPosition="left" mx="auto">
          {rows}
        </Accordion>
      </Paper>

    </>
  )
}

export default Email;
