import {Accordion, Box, Flex, Paper, Text} from "@mantine/core";
import {ApiForm} from "../../SDK/formsapi";
import Form from "./Form";

const Forms = ({forms}: { forms: ApiForm[] | undefined }) => {
  const rows = forms?.map((f, i) => (
    <Accordion.Item value={`item-${i}`} key={f.id}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Accordion.Control>
          <Flex
            gap="sm"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text>{f.name}</Text>
          </Flex>
        </Accordion.Control>
      </Box>
      <Accordion.Panel>
        <Form  form={f} />
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Paper shadow="xl" radius="xl" p="xl">
        <Accordion chevronPosition="left" mx="auto">
          {rows}
        </Accordion>
      </Paper>

    </>
  )
}

export default Forms;
