import {NavLink} from "@mantine/core";
import {IconListCheck} from "@tabler/icons-react";
import {Link} from "react-router-dom";

const TaskLinks = () => {
  return (
    <>
      <Link to={"/tasks"}><NavLink label="Tasks" icon={<IconListCheck size={16}/>}/></Link>
    </>
  )
}

export default TaskLinks;
