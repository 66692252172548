import {NavLink} from "@mantine/core";
import {IconSitemap, IconTextCaption} from "@tabler/icons-react";
import {Link} from "react-router-dom";

const FormsLinks = () => {
  return (
    <>
      <Link to={"/forms/sites"}><NavLink label="Sites" icon={<IconSitemap size={16}/>}/></Link>
      <Link to={"/forms/forms"}><NavLink label="Forms" icon={<IconTextCaption size={16}/>}/></Link>
    </>
  )
}

export default FormsLinks;
