import {useForm} from "@mantine/form";
import {Button, Group, MultiSelect, Notification, Select, TextInput} from "@mantine/core";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {IconCheck} from "@tabler/icons-react";
import {SubmitTag, SubmitTask} from "../../Services/TasksService";
import {ApiPriority, ApiTag, ApiTask} from "../../SDK/tasksapi";
import {useTranslation} from "react-i18next";

export interface TaskFormProps {
  priorities: ApiPriority[]
  tags: ApiTag[]
  setLoading: any
}

const TaskForm = ({priorities, tags, setLoading}: TaskFormProps) => {
  const {user} = useAuthenticator((context) => [context.user]);
  const { t } = useTranslation();

  const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const [hasSuccess, setHasSuccess] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagData, setTagData] = useState(tags.map((t) => {
    return {value: t.id?.toString() || '', label: t.name}
  }));


  const form = useForm<ApiTask>({
    initialValues: {priority_id: 1, title: '', description: '', tags: []},
  });

  useEffect(() => {
    let t: ApiTag[] = [];
    selectedTags.forEach((tg) => {
        const tag = tags.find(e => e.id === parseInt(tg));
        tag && t.push(tag);
      }
    )
    form.setFieldValue('tags', t)
  }, [selectedTags, tags])

  return (
    <div style={{maxWidth: 320, margin: 'auto'}}>
      {hasSuccess && <Notification icon={<IconCheck size={18}/>} color="teal" title={t('task.create.title')}
                                   onClose={() => setHasSuccess(false)}>
        {t('task.create.success')}
      </Notification>}
      <TextInput mt="xs" label="Title" placeholder="Title" {...form.getInputProps('title')}/>
      <TextInput label="Description" placeholder="Description" {...form.getInputProps('description')} />
      <MultiSelect value={selectedTags} onChange={setSelectedTags} data={tagData} searchable
                   creatable
                   getCreateLabel={(query) => `+ Create ${query}`}
                   onCreate={(query) => {
                     const item = {value: query, label: query};
                     SubmitTag({name: query}, token)
                       .then((r) => {
                         r && setTagData((current) =>
                           [...current, {value: r.id?.toString() || '', label: r.name}]);
                         setLoading(true);
                       })
                     return item;
                   }}
      />
      {t('task.priority')}
      <Select data={priorities.map((p) => {
        return {value: p.id?.toString() || '', label: p.name}
      })}/>

      <Group position="center" mt="xl">
        <Button
          variant="outline"
          onClick={() => SubmitTask(form.values, token)
            .then((r) => {
              setHasSuccess(r)
              form.reset()
              setSelectedTags([])
            })}
        >
          {t('form.submit')}
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            form.reset()
            setSelectedTags([])
          }
          }
        >
          {t('form.clear')}
        </Button>
      </Group>
    </div>
  )
}

export default TaskForm;
