import React, {Suspense, useState} from 'react';
import './App.css';
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Aliases from "./components/Mail/Aliases";
import {ActionIcon, AppShell, Burger, Group, Header, MediaQuery, Navbar, Text, useMantineTheme} from "@mantine/core";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NavbarLinks from "./components/Appshell/NavbarLinks";
import Email from "./components/Mail/Email";
import {Home} from "./components/Home/Home";
import Tasks from "./components/Task/Tasks";
import {useTranslation} from "react-i18next";
import {IconLanguage} from '@tabler/icons-react';
import Sites from "./components/Forms/Sites";


function App() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const {i18n} = useTranslation();

  const handleLanguageChange = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('pt');
    } else {
      i18n.changeLanguage('en');
    }
  }

  return (
    <>
      <Suspense fallback="loading">
        <Router>
          <AppShell
            padding="md"
            navbar={<Navbar p="md" hiddenBreakpoint="sm" hidden={!opened}
                            width={{sm: 200, lg: 300}}><NavbarLinks/></Navbar>}
            header={
              <Header height={50} p="md">
                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                  <MediaQuery largerThan="sm" styles={{display: 'none'}}>
                    <Burger
                      opened={opened}
                      onClick={() => setOpened((o) => !o)}
                      size="sm"
                      color={theme.colors.gray[6]}
                      mr="xl"
                    />
                  </MediaQuery>
                  <Group>
                    <Text>LuizTec</Text>
                    <ActionIcon variant="default" onClick={handleLanguageChange}><IconLanguage size={16}/></ActionIcon>
                  </Group>

                </div>
              </Header>
            }
            styles={(theme) => ({
              main: {backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0]},
            })}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
          >

            <Routes>
              <Route path="/about" element={<About/>}/>
              <Route path="/aliases" element={<Aliases/>}/>
              <Route path="/emails" element={<Email/>}/>
              <Route path="/tasks" element={<Tasks/>}/>
              <Route path="/forms/sites" element={<Sites/>}/>
              <Route path="/" element={<Home/>}/>
            </Routes>

          </AppShell>
        </Router>
      </Suspense>
    </>
  );
}

export default withAuthenticator(App);

function About() {
  return <h2>About</h2>;
}
