import {useAuthenticator} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {DeleteAlias, GetAliases} from "../../Services/MailMgnService";
import {ApiMailAlias} from "../../SDK/mailmgn";
import {Accordion, ActionIcon, Box, Button, Code, Drawer, Flex, Paper, Text} from "@mantine/core";
import {IconClipboardCopy, IconSquarePlus} from "@tabler/icons-react";
import AliasForm from "./AliasForm";
import {DeleteButton} from "./DeleteButton";

const Aliases = () => {
  const [opened, setOpened] = useState(false);
  const [aliases, setAliases] = useState<ApiMailAlias[]>([]);
  const {user} = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    isLoading && GetAliases(user.getSignInUserSession()?.getIdToken().getJwtToken())
      .then((d) => setAliases(d ? d : []));
    setIsLoading(false)
  }, [user, isLoading])

  const handleDelete = (alias_id: number | undefined) => {
    alias_id && DeleteAlias(alias_id, user.getSignInUserSession()?.getIdToken().getJwtToken())
      .then(() => setIsLoading(true))
  }

  const handleCopyText = (text: string | undefined) => {
    if ('clipboard' in navigator && text) {
      navigator.clipboard.writeText(text);
    }
  }

  const rows = aliases.map((a, i) => (
    <Accordion.Item value={`item-${i}`} key={i}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Accordion.Control>
          <Flex
            gap="sm"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text>{a.alias}@{a.domain}</Text>
          </Flex>
        </Accordion.Control>
        <ActionIcon variant="outline" size={18} value={a.alias} onClick={() => handleCopyText(`${a.alias}@${a.domain}`)}>
          <IconClipboardCopy size={16}/>
        </ActionIcon>
      </Box>
      <Accordion.Panel>
        <Text>Destination: <Code>{a.destination}</Code></Text>
        <DeleteButton itemId={a.id} handleDelete={handleDelete}/>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
          setIsLoading(true)
        }}
        title="Create Alias"
        padding="xl"
        size="xl"
      >
        <AliasForm/>
      </Drawer>
      <Paper shadow="xl" radius="xl" p="xl">
        <Button leftIcon={<IconSquarePlus/>} variant="outline" color="blue"
                onClick={() => setOpened(true)}>New</Button>
        <Accordion chevronPosition="left" mx="auto">
          {rows}
        </Accordion>
      </Paper>
    </>
  )
}

export default Aliases;
