import axios from "axios";
import {ApiSite, SitesApi} from "../SDK/formsapi";

const basePath = process.env.REACT_APP_MAIL_API ? process.env.REACT_APP_MAIL_API : '/forms'
const FORMS_SITES_API = new SitesApi(undefined, basePath, axios);

export const GetSites = (token: string | undefined) => {
  return FORMS_SITES_API.apiSitesGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}

export const SubmitFormSite = (site: ApiSite, token: string | undefined) => {
  return FORMS_SITES_API.apiSitesPost(site, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 201;
  }).catch((e) => {
    console.log(e);
    return false
  })
}
