/* tslint:disable */
/* eslint-disable */
/**
 * MailMGn API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiDomain
 */
export interface ApiDomain {
    /**
     * 
     * @type {string}
     * @memberof ApiDomain
     */
    'domain': string;
    /**
     * 
     * @type {number}
     * @memberof ApiDomain
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ApiMailAlias
 */
export interface ApiMailAlias {
    /**
     * 
     * @type {string}
     * @memberof ApiMailAlias
     */
    'alias': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailAlias
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailAlias
     */
    'destination': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailAlias
     */
    'domain': string;
    /**
     * 
     * @type {number}
     * @memberof ApiMailAlias
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ApiMailAliasReq
 */
export interface ApiMailAliasReq {
    /**
     * 
     * @type {string}
     * @memberof ApiMailAliasReq
     */
    'alias': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailAliasReq
     */
    'destination': string;
    /**
     * 
     * @type {number}
     * @memberof ApiMailAliasReq
     */
    'domain_id': number;
}
/**
 * 
 * @export
 * @interface ApiMailUserReq
 */
export interface ApiMailUserReq {
    /**
     * 
     * @type {number}
     * @memberof ApiMailUserReq
     */
    'domain_id': number;
    /**
     * 
     * @type {string}
     * @memberof ApiMailUserReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailUserReq
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ApiMailUserRes
 */
export interface ApiMailUserRes {
    /**
     * 
     * @type {string}
     * @memberof ApiMailUserRes
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailUserRes
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof ApiMailUserRes
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof ApiMailUserRes
     */
    'id': number;
}

/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete aliases
         * @summary Delete aliases
         * @param {number} alias AliasId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasesDelete: async (alias: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alias' is not null or undefined
            assertParamExists('aliasesDelete', 'alias', alias)
            const localVarPath = `/aliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get aliases
         * @summary Get aliases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/aliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create alias
         * @summary Create alias
         * @param {ApiMailAliasReq} alias Alias Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasesPost: async (alias: ApiMailAliasReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alias' is not null or undefined
            assertParamExists('aliasesPost', 'alias', alias)
            const localVarPath = `/aliases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get domains
         * @summary Get domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domains`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete email
         * @summary Delete email
         * @param {number} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsDelete: async (email: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('emailsDelete', 'email', email)
            const localVarPath = `/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get email
         * @summary Get email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create email
         * @summary Create email
         * @param {ApiMailUserReq} email Email Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsPost: async (email: ApiMailUserReq, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('emailsPost', 'email', email)
            const localVarPath = `/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(email, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete aliases
         * @summary Delete aliases
         * @param {number} alias AliasId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aliasesDelete(alias: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aliasesDelete(alias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get aliases
         * @summary Get aliases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aliasesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiMailAlias>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aliasesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create alias
         * @summary Create alias
         * @param {ApiMailAliasReq} alias Alias Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aliasesPost(alias: ApiMailAliasReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aliasesPost(alias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get domains
         * @summary Get domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domainsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiDomain>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domainsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete email
         * @summary Delete email
         * @param {number} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailsDelete(email: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailsDelete(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get email
         * @summary Get email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiMailUserRes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create email
         * @summary Create email
         * @param {ApiMailUserReq} email Email Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailsPost(email: ApiMailUserReq, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailsPost(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailApiFp(configuration)
    return {
        /**
         * Delete aliases
         * @summary Delete aliases
         * @param {number} alias AliasId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasesDelete(alias: number, options?: any): AxiosPromise<string> {
            return localVarFp.aliasesDelete(alias, options).then((request) => request(axios, basePath));
        },
        /**
         * get aliases
         * @summary Get aliases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasesGet(options?: any): AxiosPromise<Array<ApiMailAlias>> {
            return localVarFp.aliasesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Create alias
         * @summary Create alias
         * @param {ApiMailAliasReq} alias Alias Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasesPost(alias: ApiMailAliasReq, options?: any): AxiosPromise<string> {
            return localVarFp.aliasesPost(alias, options).then((request) => request(axios, basePath));
        },
        /**
         * get domains
         * @summary Get domains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domainsGet(options?: any): AxiosPromise<Array<ApiDomain>> {
            return localVarFp.domainsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete email
         * @summary Delete email
         * @param {number} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsDelete(email: number, options?: any): AxiosPromise<string> {
            return localVarFp.emailsDelete(email, options).then((request) => request(axios, basePath));
        },
        /**
         * get email
         * @summary Get email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsGet(options?: any): AxiosPromise<Array<ApiMailUserRes>> {
            return localVarFp.emailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Create email
         * @summary Create email
         * @param {ApiMailUserReq} email Email Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsPost(email: ApiMailUserReq, options?: any): AxiosPromise<string> {
            return localVarFp.emailsPost(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * Delete aliases
     * @summary Delete aliases
     * @param {number} alias AliasId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public aliasesDelete(alias: number, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).aliasesDelete(alias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get aliases
     * @summary Get aliases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public aliasesGet(options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).aliasesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create alias
     * @summary Create alias
     * @param {ApiMailAliasReq} alias Alias Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public aliasesPost(alias: ApiMailAliasReq, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).aliasesPost(alias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get domains
     * @summary Get domains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public domainsGet(options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).domainsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete email
     * @summary Delete email
     * @param {number} email Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public emailsDelete(email: number, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).emailsDelete(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get email
     * @summary Get email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public emailsGet(options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).emailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create email
     * @summary Create email
     * @param {ApiMailUserReq} email Email Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public emailsPost(email: ApiMailUserReq, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).emailsPost(email, options).then((request) => request(this.axios, this.basePath));
    }
}


