import {ApiMailAliasReq, ApiMailUserReq, MailApi} from "../SDK/mailmgn";
import axios from "axios";

const basePath = process.env.REACT_APP_MAIL_API ? process.env.REACT_APP_MAIL_API : '/mail/api'
const MAIL_MNG_API = new MailApi(undefined, basePath, axios);

export const GetAliases = (token: string | undefined) => {
  return MAIL_MNG_API.aliasesGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}

export const SubmitAlias = (alias: ApiMailAliasReq, token: string | undefined) => {
  return MAIL_MNG_API.aliasesPost(alias, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 201;
  }).catch((e) => {
    console.log(e);
    return false
  })
}

export const DeleteAlias = (alias_id: number, token: string | undefined) => {
  return MAIL_MNG_API.aliasesDelete(alias_id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 202;
  }).catch((e) => {
    console.log(e);
    return false
  })
}

export const GetEmails = (token: string | undefined) => {
  return MAIL_MNG_API.emailsGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}

export const SubmitEmail = (email: ApiMailUserReq, token: string | undefined) => {
  return MAIL_MNG_API.emailsPost(email, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 201;
  }).catch((e) => {
    console.log(e);
    return false
  })
}

export const DeleteEmail = (email_id: number, token: string | undefined) => {
  return MAIL_MNG_API.emailsDelete(email_id, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 202;
  }).catch((e) => {
    console.log(e);
    return false
  })
}

export const GetDomains = (token: string | undefined) => {
  return MAIL_MNG_API.domainsGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}
