import {ApiDomain} from "../../SDK/mailmgn";
import {NativeSelect} from "@mantine/core";
import {IconAt} from "@tabler/icons-react";

export async function convertDomainsToNativeSelectData(domains: ApiDomain[]) {
  let data: any = [{value: 0, label: ''}]
  domains.forEach((d) => data.push({value: d.id, label: d.domain}))
  return data
}

export function domainSelection(domainsData: any[], handleDomain: (domain: string) => void) {
  return (
    <NativeSelect
      icon={<IconAt/>}
      data={domainsData}
      styles={{
        input: {
          fontWeight: 500,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: 150
        },
      }}
      onChange={(e) => handleDomain(e.target.value)}
    />
  )
}
