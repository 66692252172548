/* tslint:disable */
/* eslint-disable */
/**
 * MailMGn API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiGoal
 */
export interface ApiGoal {
    /**
     * 
     * @type {string}
     * @memberof ApiGoal
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGoal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGoal
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiGoal
     */
    'priority_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGoal
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiPriority
 */
export interface ApiPriority {
    /**
     * 
     * @type {string}
     * @memberof ApiPriority
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<ApiGoal>}
     * @memberof ApiPriority
     */
    'goal'?: Array<ApiGoal>;
    /**
     * 
     * @type {number}
     * @memberof ApiPriority
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPriority
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ApiTask>}
     * @memberof ApiPriority
     */
    'task'?: Array<ApiTask>;
    /**
     * 
     * @type {string}
     * @memberof ApiPriority
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiTag
 */
export interface ApiTag {
    /**
     * 
     * @type {string}
     * @memberof ApiTag
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTag
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTag
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ApiTask>}
     * @memberof ApiTag
     */
    'tasks'?: Array<ApiTask>;
    /**
     * 
     * @type {string}
     * @memberof ApiTag
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTag
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface ApiTask
 */
export interface ApiTask {
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'due_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTask
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiTask
     */
    'is_completed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiTask
     */
    'priority_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'status'?: string;
    /**
     * 
     * @type {Array<ApiTag>}
     * @memberof ApiTask
     */
    'tags'?: Array<ApiTag>;
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTask
     */
    'user_id'?: string;
}

/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get Priorities
         * @summary get Priorities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrioritiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/priorities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get tags
         * @summary get Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get tag
         * @summary Create tag
         * @param {ApiTag} tag Tag Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsPost: async (tag: ApiTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('apiTagsPost', 'tag', tag)
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete tag
         * @summary Delete tag
         * @param {number} tagId tagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsTagIdDelete: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('apiTagsTagIdDelete', 'tagId', tagId)
            const localVarPath = `/api/tags/{tagId}`
                .replace(`{${"tagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get task
         * @summary Create task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get task
         * @summary Create task
         * @param {ApiTask} task Task Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksPost: async (task: ApiTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'task' is not null or undefined
            assertParamExists('apiTasksPost', 'task', task)
            const localVarPath = `/api/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(task, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update task
         * @summary Update task
         * @param {ApiTask} tag Task Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksPut: async (tag: ApiTask, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('apiTasksPut', 'tag', tag)
            const localVarPath = `/api/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get task
         * @summary Create task
         * @param {number} taskId taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksTaskIdGet: async (taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('apiTasksTaskIdGet', 'taskId', taskId)
            const localVarPath = `/api/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * get Priorities
         * @summary get Priorities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrioritiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiPriority>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrioritiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get tags
         * @summary get Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get tag
         * @summary Create tag
         * @param {ApiTag} tag Tag Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagsPost(tag: ApiTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagsPost(tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete tag
         * @summary Delete tag
         * @param {number} tagId tagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagsTagIdDelete(tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagsTagIdDelete(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get task
         * @summary Create task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTasksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiTask>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTasksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get task
         * @summary Create task
         * @param {ApiTask} task Task Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTasksPost(task: ApiTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTasksPost(task, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update task
         * @summary Update task
         * @param {ApiTask} tag Task Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTasksPut(tag: ApiTask, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTasksPut(tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get task
         * @summary Create task
         * @param {number} taskId taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTasksTaskIdGet(taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTasksTaskIdGet(taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * get Priorities
         * @summary get Priorities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrioritiesGet(options?: any): AxiosPromise<Array<ApiPriority>> {
            return localVarFp.apiPrioritiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get tags
         * @summary get Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsGet(options?: any): AxiosPromise<Array<ApiTag>> {
            return localVarFp.apiTagsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get tag
         * @summary Create tag
         * @param {ApiTag} tag Tag Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsPost(tag: ApiTag, options?: any): AxiosPromise<ApiTag> {
            return localVarFp.apiTagsPost(tag, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete tag
         * @summary Delete tag
         * @param {number} tagId tagId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagsTagIdDelete(tagId: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiTagsTagIdDelete(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * get task
         * @summary Create task
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGet(options?: any): AxiosPromise<Array<ApiTask>> {
            return localVarFp.apiTasksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * get task
         * @summary Create task
         * @param {ApiTask} task Task Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksPost(task: ApiTask, options?: any): AxiosPromise<string> {
            return localVarFp.apiTasksPost(task, options).then((request) => request(axios, basePath));
        },
        /**
         * Update task
         * @summary Update task
         * @param {ApiTask} tag Task Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksPut(tag: ApiTask, options?: any): AxiosPromise<string> {
            return localVarFp.apiTasksPut(tag, options).then((request) => request(axios, basePath));
        },
        /**
         * get task
         * @summary Create task
         * @param {number} taskId taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksTaskIdGet(taskId: number, options?: any): AxiosPromise<ApiTask> {
            return localVarFp.apiTasksTaskIdGet(taskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * get Priorities
     * @summary get Priorities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiPrioritiesGet(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiPrioritiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get tags
     * @summary get Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTagsGet(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTagsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get tag
     * @summary Create tag
     * @param {ApiTag} tag Tag Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTagsPost(tag: ApiTag, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTagsPost(tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete tag
     * @summary Delete tag
     * @param {number} tagId tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTagsTagIdDelete(tagId: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTagsTagIdDelete(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get task
     * @summary Create task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTasksGet(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTasksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get task
     * @summary Create task
     * @param {ApiTask} task Task Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTasksPost(task: ApiTask, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTasksPost(task, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update task
     * @summary Update task
     * @param {ApiTask} tag Task Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTasksPut(tag: ApiTask, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTasksPut(tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get task
     * @summary Create task
     * @param {number} taskId taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTasksTaskIdGet(taskId: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTasksTaskIdGet(taskId, options).then((request) => request(this.axios, this.basePath));
    }
}


