import {NavLink} from '@mantine/core';
import {IconChevronRight, IconFileText, IconHome2, IconListCheck, IconMail} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import MailLinks from "./MailLinks";
import {Button} from "@aws-amplify/ui-react";
import React from "react";

import {Auth} from 'aws-amplify';
import TaskLinks from "./TaskLinks";
import FormsLinks from "./FormsLinks";


const NavbarLinks = () => {

  async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <>
      <Link to={"/"}><NavLink label="Home" icon={<IconHome2 size={16}/>}/></Link>
      <NavLink
        label="Mail"
        icon={<IconMail size={16}/>}
        rightSection={<IconChevronRight size={12}/>}
        children={<MailLinks/>}
      />
      <NavLink
        label="Tasks"
        icon={<IconListCheck size={16}/>}
        rightSection={<IconChevronRight size={12}/>}
        children={<TaskLinks/>}
      />
      <NavLink
        label="Forms"
        icon={<IconFileText size={16}/>}
        rightSection={<IconChevronRight size={12}/>}
        children={<FormsLinks/>}
      />
      <Button onClick={signOut}>Sign out</Button>
    </>
  )
}

export default NavbarLinks
