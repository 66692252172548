import {useForm} from "@mantine/form";
import {Button, Flex, Group, Notification, TextInput} from "@mantine/core";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {GetDomains, SubmitAlias} from "../../Services/MailMgnService";
import {useEffect, useState} from "react";
import {IconCheck} from "@tabler/icons-react";
import {convertDomainsToNativeSelectData, domainSelection} from "./Common";

const AliasForm = () => {
  const {user} = useAuthenticator((context) => [context.user]);

  const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const [hasSuccess, setHasSuccess] = useState(false);
  const [domainsData, setDomainsData] = useState<any[]>([]);
  const form = useForm({
    initialValues: {
      alias: '',
      destination: '',
      domain_id: 1
    },
  });

  useEffect(() => {
    GetDomains(token)
      .then((d) => convertDomainsToNativeSelectData(d ? d : []))
      .then((r) => setDomainsData(r));
  }, [token])

  function handleDomain(e: string) {
    form.setFieldValue('domain_id', parseInt(e));
  }

  return (
    <div style={{maxWidth: 320, margin: 'auto'}}>
      {hasSuccess && <Notification icon={<IconCheck size={18}/>} color="teal" title="Alias Criado"
                                   onClose={() => setHasSuccess(false)}>
          Alias criado com sucesso
      </Notification>}
      <Flex
        gap="sm"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <TextInput mt="xs" label="Alias" placeholder="Alias" {...form.getInputProps('alias')}
                   rightSection={domainSelection(domainsData, handleDomain)}
                   rightSectionWidth={150}/>
      </Flex>
      <TextInput label="Destination" placeholder="Destination" {...form.getInputProps('destination')} />

      <Group position="center" mt="xl">
        <Button
          variant="outline"
          onClick={() => SubmitAlias(form.values, token)
            .then((r) => {
              setHasSuccess(r)
              form.reset()
            })}
        >
          Submit
        </Button>
      </Group>
    </div>
  )
}

export default AliasForm;
