import axios from "axios";
import {ApiTag, ApiTask, TaskApi} from "../SDK/tasksapi";

const basePath = process.env.REACT_APP_MAIL_API ? process.env.REACT_APP_MAIL_API : '/tasks'
const TASK_API = new TaskApi(undefined, basePath, axios);


export const GetTasks = (token: string | undefined) => {
  return TASK_API.apiTasksGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}

export const GetTask = (taskId: number, token: string | undefined) => {
  return TASK_API.apiTasksTaskIdGet(taskId, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}

export const SubmitTask = (task: ApiTask, token: string | undefined) => {
  console.log(task)
  return TASK_API.apiTasksPost(task, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 201;
  }).catch((e) => {
    console.log(e);
    return false
  })
}

export const GetTags = (token: string | undefined) => {
  return TASK_API.apiTagsGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}

export const SubmitTag = (tag: ApiTag, token: string | undefined) => {
  return TASK_API.apiTagsPost(tag, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => {
    console.log(e);
    return undefined
  })
}

export const UpdateTask = (task: ApiTask, token: string | undefined) => {
  return TASK_API.apiTasksPut(task, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => {
    console.log(e);
    return undefined
  })
}

export const DeleteTag = (tagId: number, token: string | undefined) => {
  return TASK_API.apiTagsTagIdDelete(tagId, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.status === 202;
  }).catch((e) => {
    console.log(e);
    return false
  })
}

export const GetPriorities = (token: string | undefined) => {
  return TASK_API.apiPrioritiesGet({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((r) => {
    return r.data
  }).catch((e) => console.log(e))
}
