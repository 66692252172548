/* tslint:disable */
/* eslint-disable */
/**
 * Forms API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiForm
 */
export interface ApiForm {
    /**
     * 
     * @type {string}
     * @memberof ApiForm
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiForm
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiForm
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiForm
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiForm
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiForm
     */
    'site_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiForm
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiForm
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ApiSite
 */
export interface ApiSite {
    /**
     * 
     * @type {string}
     * @memberof ApiSite
     */
    'api_key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSite
     */
    'createdAt'?: string;
    /**
     * 
     * @type {Array<ApiForm>}
     * @memberof ApiSite
     */
    'forms'?: Array<ApiForm>;
    /**
     * 
     * @type {number}
     * @memberof ApiSite
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiSite
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSite
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSite
     */
    'user_id'?: string;
}

/**
 * FormsApi - axios parameter creator
 * @export
 */
export const FormsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create form
         * @summary Create form
         * @param {string} apiKey apiKey
         * @param {ApiForm} task Form Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormsApiKeyPost: async (apiKey: string, task: ApiForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKey' is not null or undefined
            assertParamExists('apiFormsApiKeyPost', 'apiKey', apiKey)
            // verify required parameter 'task' is not null or undefined
            assertParamExists('apiFormsApiKeyPost', 'task', task)
            const localVarPath = `/api/forms/{apiKey}`
                .replace(`{${"apiKey"}}`, encodeURIComponent(String(apiKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(task, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormsApi - functional programming interface
 * @export
 */
export const FormsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormsApiAxiosParamCreator(configuration)
    return {
        /**
         * create form
         * @summary Create form
         * @param {string} apiKey apiKey
         * @param {ApiForm} task Form Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormsApiKeyPost(apiKey: string, task: ApiForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormsApiKeyPost(apiKey, task, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FormsApi - factory interface
 * @export
 */
export const FormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormsApiFp(configuration)
    return {
        /**
         * create form
         * @summary Create form
         * @param {string} apiKey apiKey
         * @param {ApiForm} task Form Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormsApiKeyPost(apiKey: string, task: ApiForm, options?: any): AxiosPromise<string> {
            return localVarFp.apiFormsApiKeyPost(apiKey, task, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormsApi - object-oriented interface
 * @export
 * @class FormsApi
 * @extends {BaseAPI}
 */
export class FormsApi extends BaseAPI {
    /**
     * create form
     * @summary Create form
     * @param {string} apiKey apiKey
     * @param {ApiForm} task Form Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormsApi
     */
    public apiFormsApiKeyPost(apiKey: string, task: ApiForm, options?: AxiosRequestConfig) {
        return FormsApiFp(this.configuration).apiFormsApiKeyPost(apiKey, task, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SitesApi - axios parameter creator
 * @export
 */
export const SitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get sites
         * @summary Get sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create site
         * @summary Create site
         * @param {ApiSite} task Site Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitesPost: async (task: ApiSite, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'task' is not null or undefined
            assertParamExists('apiSitesPost', 'task', task)
            const localVarPath = `/api/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(task, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete site
         * @summary Delete site
         * @param {number} siteId siteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitesSiteIdDelete: async (siteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('apiSitesSiteIdDelete', 'siteId', siteId)
            const localVarPath = `/api/sites/{siteId}`
                .replace(`{${"siteId"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SitesApi - functional programming interface
 * @export
 */
export const SitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SitesApiAxiosParamCreator(configuration)
    return {
        /**
         * get sites
         * @summary Get sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSitesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiSite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSitesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * create site
         * @summary Create site
         * @param {ApiSite} task Site Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSitesPost(task: ApiSite, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSitesPost(task, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete site
         * @summary Delete site
         * @param {number} siteId siteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSitesSiteIdDelete(siteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSitesSiteIdDelete(siteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SitesApi - factory interface
 * @export
 */
export const SitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SitesApiFp(configuration)
    return {
        /**
         * get sites
         * @summary Get sites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitesGet(options?: any): AxiosPromise<Array<ApiSite>> {
            return localVarFp.apiSitesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * create site
         * @summary Create site
         * @param {ApiSite} task Site Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitesPost(task: ApiSite, options?: any): AxiosPromise<string> {
            return localVarFp.apiSitesPost(task, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete site
         * @summary Delete site
         * @param {number} siteId siteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSitesSiteIdDelete(siteId: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiSitesSiteIdDelete(siteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SitesApi - object-oriented interface
 * @export
 * @class SitesApi
 * @extends {BaseAPI}
 */
export class SitesApi extends BaseAPI {
    /**
     * get sites
     * @summary Get sites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public apiSitesGet(options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).apiSitesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create site
     * @summary Create site
     * @param {ApiSite} task Site Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public apiSitesPost(task: ApiSite, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).apiSitesPost(task, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete site
     * @summary Delete site
     * @param {number} siteId siteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SitesApi
     */
    public apiSitesSiteIdDelete(siteId: number, options?: AxiosRequestConfig) {
        return SitesApiFp(this.configuration).apiSitesSiteIdDelete(siteId, options).then((request) => request(this.axios, this.basePath));
    }
}


