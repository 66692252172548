import {useAuthenticator} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {Button, Flex, Group, Notification, PasswordInput, TextInput} from "@mantine/core";
import {IconCheck} from "@tabler/icons-react";
import {GetDomains, SubmitEmail} from "../../Services/MailMgnService";
import {convertDomainsToNativeSelectData, domainSelection} from "./Common";

const EmailForm = () => {
  const {user} = useAuthenticator((context) => [context.user]);
  const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const [hasSuccess, setHasSuccess] = useState(false);
  const [domainsData, setDomainsData] = useState([]);

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      domain_id: 0
    },
  });

  useEffect(() => {
    GetDomains(token)
      .then((d) => convertDomainsToNativeSelectData(d ? d : []))
      .then((r) => setDomainsData(r));
  }, [token])

  function handleDomain(e: string) {
    form.setFieldValue('domain_id', parseInt(e));
  }

  return (
    <div style={{maxWidth: 320, margin: 'auto'}}>
      {hasSuccess && <Notification icon={<IconCheck size={18}/>} color="teal" title="Email Criado"
                                   onClose={() => setHasSuccess(false)}>
          Email criado com sucesso
      </Notification>}
      <Flex
        gap="sm"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <TextInput mt="xs" label="Email" placeholder="Email" {...form.getInputProps('email')}
                   rightSection={domainSelection(domainsData, handleDomain)}
                   rightSectionWidth={150}/>
      </Flex>
      <PasswordInput mt="md" label="Password" withAsterisk {...form.getInputProps('password')} />

      <Group position="center" mt="xl">
        <Button
          variant="outline"
          onClick={() => SubmitEmail(form.values, token)
            .then(() => {
              setHasSuccess(true)
              form.reset()
            })}
        >
          Submit
        </Button>
      </Group>
    </div>
  )
}

export default EmailForm;
