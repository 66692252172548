import {useAuthenticator} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {Accordion, ActionIcon, Badge, Box, Checkbox, Code, Drawer, Flex, Paper, Text} from "@mantine/core";
import {IconSquarePlus} from "@tabler/icons-react";
import {ApiPriority, ApiTag, ApiTask} from "../../SDK/tasksapi";
import {GetPriorities, GetTags, GetTasks, UpdateTask} from "../../Services/TasksService";
import TaskForm from "./TaskForm";
import {useTranslation} from "react-i18next";

const Tasks = () => {
  const [opened, setOpened] = useState(false);
  const [tasks, setTasks] = useState<ApiTask[]>([]);
  const {user} = useAuthenticator((context) => [context.user]);
  const token = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const [isLoading, setIsLoading] = useState(true);
  const [priorities, setPriorities] = useState<ApiPriority[]>([]);
  const [tags, setTags] = useState<ApiTag[]>([]);
  const [completed, setCompleted] = useState<(number | undefined)[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isLoading) {
      GetTasks(token)
        .then((d) => setTasks(d ? d : []));
      GetPriorities(token)
        .then((p) => setPriorities(p ? p : []));
      GetTags(token)
        .then((t) => setTags(t ? t : []));
    }
    setIsLoading(false)
  }, [user, isLoading, token])

  const handleCompleteTask = (t: ApiTask) => {
    t.is_completed = true
    UpdateTask(t, token).then(() => setCompleted([...completed, t.id]))
  }

  const rows = tasks.map((t, i) => (
    <Accordion.Item value={`item-${i}`} key={t.id}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <ActionIcon size={18} value={t.title}>
          <Checkbox
            label=""
            color="cyan"
            radius="lg"
            onChange={() => handleCompleteTask(t)}
          />
        </ActionIcon>
        <Accordion.Control>
          <Flex
            gap="sm"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <div>
              <Text strikethrough={t.is_completed}>{t.title}</Text>
            </div>
            <div>
              {t.tags?.map((tg) =>
                <Badge size="sm" radius="md" variant="outline" key={tg.id}>{tg.name}</Badge>)}
            </div>
          </Flex>
        </Accordion.Control>
      </Box>
      <Accordion.Panel>
        <Text><Code>{t.description}</Code></Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
          setIsLoading(true)
        }}
        title="Create Task"
        padding="xl"
        size="xl"
      >
        <TaskForm priorities={priorities} tags={tags} setLoading={setIsLoading}/>
      </Drawer>

      <Paper shadow="xl" radius="xl" p="xl">
        <Flex direction="row" justify="flex-end">
          <ActionIcon size={30} color={"blue"} onClick={() => setOpened(true)}>
            <IconSquarePlus/>
          </ActionIcon>
        </Flex>

        <Accordion mx="auto">
          {rows}
        </Accordion>
      </Paper>
    </>
  )
}

export default Tasks;
