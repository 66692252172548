import {useEffect, useState} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {IconRefresh, IconSquarePlus} from "@tabler/icons-react";
import {Accordion, ActionIcon, Box, Button, Code, Drawer, Flex, Group, Modal, Paper, Text} from "@mantine/core";
import EmailForm from "./AddSite";
import {ApiForm, ApiSite} from "../../SDK/formsapi";
import {GetSites} from "../../Services/Forms";
import Forms from "./Forms";
import {useTranslation} from "react-i18next";

const Sites = () => {
  const [opened, setOpened] = useState(false);
  const [sites, setSites] = useState<ApiSite[]>([]);
  const {user} = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForms, setShowForms] = useState<{ show: boolean, forms: ApiForm[] | undefined }>({show: false, forms: []});
  const {t} = useTranslation();

  useEffect(() => {
    isLoading && GetSites(user.getSignInUserSession()?.getIdToken().getJwtToken())
      .then((d) => setSites(d ? d : []));
    setIsLoading(false)
  }, [user, isLoading])

  const rows = sites.map((s, i) => (
    <Accordion.Item value={`item-${i}`} key={s.id}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Accordion.Control>
          <Flex
            gap="sm"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text>{s.name}</Text>
          </Flex>
        </Accordion.Control>
      </Box>
      <Accordion.Panel>
        <p>
          <Code>{s.api_key}</Code>
        </p>
        <Text>{s.createdAt}</Text>

        <Button variant={"light"} size={"xs"} onClick={() => setShowForms({show: true, forms: s.forms})}>
          {t('form.show')}
        </Button>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Modal
        opened={showForms.show}
        onClose={() => setShowForms({show: false, forms: []})}
        title={""}
      >
        <Forms forms={showForms.forms}/>
      </Modal>
      <Drawer
        opened={opened}
        onClose={() => {
          setOpened(false);
          setIsLoading(true)
        }}
        title={t('form.site.create.title')}
        padding="xl"
        size="xl"
      >
        <EmailForm/>
      </Drawer>
      <Paper shadow="xl" radius="xl" p="xl">
        <Group>
          <ActionIcon onClick={() => setOpened(true)}><IconSquarePlus size={16}/></ActionIcon>
          <ActionIcon onClick={() => setIsLoading(true)}><IconRefresh size={16}/></ActionIcon>
        </Group>

        <Accordion chevronPosition="left" mx="auto">
          {rows}
        </Accordion>
      </Paper>

    </>
  )
}

export default Sites;
